import { fetchUtils } from 'react-admin';
import { useSelector } from 'react-redux';

const useHttpClient = () => {
    const token = useSelector(state => state.auth.token);

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        return fetchUtils.fetchJson(url, options);
    };

    return httpClient;
};

export default useHttpClient;
