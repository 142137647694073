import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionInfo } from '../slices/subscriptionSlice';
import { CheckCircleIcon, CalendarIcon, InformationCircleIcon, XCircleIcon, ExclamationCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'; // Importer les icônes supplémentaires

const SubscriptionInfo = () => {
    const dispatch = useDispatch();
    const { token, user } = useSelector(state => state.auth);
    const subscription = useSelector(state => state.subscription);

    useEffect(() => {
        if (token && user) {
            dispatch(fetchSubscriptionInfo());
        }
    }, [dispatch, token, user]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-8">Informations sur votre Abonnement</h1>
            {subscription.loading && (
                <div className="flex items-center space-x-2 mb-6">
                    <svg className="w-6 h-6 text-blue-500 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 2v2a6 6 0 0 1 0 12v2a8 8 0 0 0 0-16z" />
                    </svg>
                    <span className="text-blue-500">Chargement en cours...</span>
                </div>
            )}
            {subscription.error && (
                <div className="text-red-500 text-center mb-6">
                    <ExclamationCircleIcon className="w-6 h-6 inline-block mr-2" />
                    <p>Nous n'avons pas pu récupérer les informations de votre abonnement.</p>
                    <p>Il est possible que vous n'ayez pas d'abonnement actif. Veuillez vérifier vos informations ou contacter notre support pour plus d'aide.</p>
                </div>
            )}
            {subscription.data && subscription.data.status === 'no_subscription' && (
                <div className="text-gray-600 text-center mb-6">
                    <XCircleIcon className="w-6 h-6 inline-block mr-2 text-gray-500" />
                    <p>Il semble que vous n'ayez pas d'abonnement actif actuellement.</p>
                    <p>Pour en savoir plus ou pour souscrire à un abonnement, veuillez visiter notre page d'abonnement.</p>
                    <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                        <PlusCircleIcon className="w-5 h-5 inline-block mr-2" />
                        S'abonner
                    </button>
                </div>
            )}
            {subscription.data && subscription.data.status !== 'no_subscription' && (
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md border border-gray-200">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">Détails de l’Abonnement</h2>
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-2">
                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            <p><strong>Status :</strong> {subscription.data.status}</p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <CalendarIcon className="w-6 h-6 text-blue-500" />
                            <p><strong>Date du prochain paiement :</strong> {new Date(subscription.data.nextPaymentDate).toLocaleDateString('fr-FR')}</p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <InformationCircleIcon className="w-6 h-6 text-gray-500" />
                            <p><strong>Informations complémentaires :</strong> {subscription.data.additionalInfo || 'Aucune information supplémentaire'}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionInfo;
