import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-6">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-6">
                <div className="flex space-x-6 md:space-x-8">
                    <a href="https://www.facebook.com" aria-label="Facebook" role="img" aria-hidden="true">
                        <FontAwesomeIcon icon={faFacebookF} className="text-white text-xl hover:text-blue-600 transition-colors duration-300" />
                    </a>
                    <a href="https://www.twitter.com" aria-label="Twitter" role="img" aria-hidden="true">
                        <FontAwesomeIcon icon={faTwitter} className="text-white text-xl hover:text-blue-400 transition-colors duration-300" />
                    </a>
                    <a href="https://www.linkedin.com" aria-label="LinkedIn" role="img" aria-hidden="true">
                        <FontAwesomeIcon icon={faLinkedinIn} className="text-white text-xl hover:text-blue-700 transition-colors duration-300" />
                    </a>
                    <a href="https://www.instagram.com" aria-label="Instagram" role="img" aria-hidden="true">
                        <FontAwesomeIcon icon={faInstagram} className="text-white text-xl hover:text-pink-500 transition-colors duration-300" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
