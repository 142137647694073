import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminPasswordPrompt from './AdminPasswordPrompt';

const PrivateRoute = ({ element }) => {
    const token = useSelector((state) => state.auth.token);
    const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

    // Vérification si l'utilisateur est connecté
    if (!token) {
        return <Navigate to="/login" />;
    }

    // Gestion de l'accès à l'admin avec un mot de passe
    if (element.type && element.type.name === 'AdminDashboard') {
        if (!isAdminAuthenticated) {
            return <AdminPasswordPrompt onAccessGranted={setIsAdminAuthenticated} />;
        }
    }

    return element;
};

export default PrivateRoute;
