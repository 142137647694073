import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, DeleteButton, DateField } from 'react-admin';

const UserList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="username" />
            <EmailField source="email" />
            <TextField source="credits" />
            <DateField source="nextPaymentDate" />
            <TextField source="status" />
            <EditButton basePath="/users" />
            <DeleteButton basePath="/users" />
        </Datagrid>
    </List>
);

export default UserList;
