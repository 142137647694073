import React, { useState } from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import UserList from '../components/users/UserList';
import UserCreate from '../components/users/UserCreate';
import UserEdit from '../components/users/UserEdit';
import useHttpClient from '../utils/useHttpClient';
import AdminPasswordPrompt from '../components/AdminPasswordPrompt'; // Importer le composant de mot de passe

const AdminDashboard = () => {
    const httpClient = useHttpClient();
    const dataProvider = jsonServerProvider('https://cvanalyzerback.osc-fr1.scalingo.io/api', httpClient);

    const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

    // Si l'authentification admin n'a pas encore eu lieu, afficher le composant pour demander le mot de passe
    if (!isAdminAuthenticated) {
        return <AdminPasswordPrompt onAccessGranted={setIsAdminAuthenticated} />;
    }

    // Si l'utilisateur est authentifié, afficher le tableau de bord Admin
    return (
        <Admin dataProvider={dataProvider} basename="/admin">
            <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} />
        </Admin>
    );
};

export default AdminDashboard;
