const authMiddleware = store => next => action => {
    if (action.type === 'apiCallBegan') {
        const token = store.getState().auth.token;
        if (token) {
            action.payload.headers = {
                ...action.payload.headers,
                'Authorization': `Bearer ${token}`
            };
        }
    }
    return next(action);
};

export default authMiddleware;
