import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { useSelector } from 'react-redux';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Assurez-vous que ces icônes existent

const Header = () => {
    // Accéder à l'état de l'utilisateur depuis le store
    const { token, user } = useSelector(state => state.auth);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="bg-gray-800 p-4">
            <nav className="relative">
                {/* Icône de menu hamburger pour les petits écrans */}
                <div className="block lg:hidden">
                    <button 
                        onClick={toggleMenu} 
                        className="text-white focus:outline-none"
                    >
                        {isMenuOpen ? (
                            <XMarkIcon className="w-6 h-6" />
                        ) : (
                            <Bars3Icon className="w-6 h-6" />
                        )}
                    </button>
                </div>

                {/* Menu pour les grands écrans */}
                <div className={`lg:flex lg:items-center lg:justify-center ${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
                    <ul className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-4 lg:space-y-0 justify-center">
                        {/* Toujours visible */}
                        <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/">Accueil</Link></li>

                        {/* Visible uniquement pour les utilisateurs non connectés */}
                        {!token && (
                            <>
                                <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/register">S'inscrire</Link></li>
                                <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/login">Se connecter</Link></li>
                            </>
                        )}

                        {/* Visible uniquement pour les utilisateurs connectés */}
                        {token && (
                            <>
                                <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/upload">Téléverser CV</Link></li>
                                <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/profile">Profil</Link></li>
                                <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/subscription">Ajouts de crédits</Link></li>
                                {/* <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/subscriptionInfo">Infos Abonnement</Link></li> */}
                                
                                {/* Visible uniquement pour l'utilisateur 'Guillaume' */}
                                {user?.username === "Guillaume" && (
                                    <li><Link className="text-white hover:bg-gray-700 p-2 rounded" to="/admin">Tableau de bord admin</Link></li>
                                )}
                            </>
                        )}

                        {/* Visible uniquement pour les utilisateurs connectés */}
                        {token && <LogoutButton />}
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;