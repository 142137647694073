import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../slices/authSlice';
import { UserIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom'; // Import de Link pour la redirection

// Ajout de l'animation CSS directement dans le composant
const styles = `
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}
.animate-spin {
    animation: spin 1s linear infinite;
}
.animate-pulse {
    animation: pulse 0.6s ease-in-out infinite;
}
`;

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch();

    const { email, password } = formData;

    const regexEmail = /^[^@\s]{2,30}@[^@\s]{2,30}\.[^@\s]{2,5}$/;
    const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const validateEmail = (email) => {
        if (!regexEmail.test(email)) {
            setEmailError("L'adresse email n'est pas valide.");
        } else {
            setEmailError('');
        }
    };

    const validatePassword = (password) => {
        if (!regexPassword.test(password)) {
            setPasswordError('Le mot de passe doit comporter au moins 8 caractères, avec au moins une majuscule, une minuscule, un chiffre et un symbole.');
        } else {
            setPasswordError('');
        }
    };

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'email') {
            validateEmail(e.target.value);
        }

        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        setError(null);
        setSubmitted(false); // Réinitialiser l'état de soumission

        if (emailError || passwordError) {
            return; // Ne pas soumettre si des erreurs existent
        }

        try {
            const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();
            if (response.ok) {
                dispatch(loginSuccess({ token: data.token, user: data.user }));
                setProfile(data.user);
                setSubmitted(true);
            } else {
                setError(data.msg || 'Erreur lors de la connexion.');
            }
        } catch (error) {
            setError('Erreur lors de la connexion.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
            <style>{styles}</style> {/* Appliquer les styles d'animation */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                {!submitted ? (
                    <>
                        <div className="flex justify-center mb-6">
                            <UserIcon className={`w-16 h-16 text-blue-500 ${submitted ? 'animate-spin' : 'animate-pulse'}`} />
                        </div>
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Connexion</h2>
                        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    required
                                    className={`w-full px-4 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                />
                                {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                            </div>
                            <div>
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Mot de passe:</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    required
                                    className={`w-full px-4 py-2 border ${passwordError ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                />
                                {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
                            </div>
                            <button
                                type="submit"
                                disabled={emailError || passwordError}
                                className="w-full bg-blue-500 text-white py-2 rounded-lg shadow hover:bg-blue-600 transition duration-300"
                            >
                                Se connecter
                            </button>
                        </form>
                        {/* Lien pour demander la réinitialisation de mot de passe */}
                        <div className="text-center mt-4">
                            <Link to="/reset-password-request" className="text-blue-500 hover:text-blue-700">
                                Mot de passe oublié ?
                            </Link>
                        </div>
                    </>
                ) : (
                    <div className="text-center">
                        <div className="flex justify-center mb-6">
                            <UserIcon className="w-16 h-16 text-blue-500" />
                        </div>
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">Connexion réussie !</h2>
                        <p className="text-lg text-gray-600 mb-4">Vous êtes maintenant connecté. Voici vos informations :</p>
                        {profile && (
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <p><strong>ID:</strong> {profile.id}</p>
                                <p><strong>Nom d'utilisateur:</strong> {profile.username}</p>
                                <p><strong>Email:</strong> {profile.email}</p>
                                <p><strong>Crédits actuel:</strong> {profile.credits}</p>
                            </div>
                        )}
                        <a href="/" className="text-blue-500 hover:text-blue-700 font-semibold">Retour à l'accueil</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
