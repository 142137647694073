import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIcon } from '@heroicons/react/24/solid';
import { CircularProgress } from '@mui/material'; 
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'; 
import { Link } from 'react-router-dom';
import UserCredits from './UserCredits';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import html2pdf from 'html2pdf.js';




const CvUploader = () => {
    const [file, setFile] = useState(null);
    const [sector, setSector] = useState('');
    const [feedback, setFeedback] = useState('');
    const [score, setScore] = useState(null);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [insufficientCredits, setInsufficientCredits] = useState(false);
    const [reloadCredits, setReloadCredits] = useState(false);
    const [cvContent, setCvContent] = useState('');  // État pour le contenu structuré du CV
    const token = useSelector((state) => state.auth.token);
    const userId = useSelector((state) => state.auth.user.id);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 5000000) { 
                setError('Le fichier dépasse la taille maximale de 5 Mo.');
                setFile(null);
            } else if (!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(selectedFile.type)) {
                setError('Seuls les fichiers PDF et DOCX sont autorisés.');
                setFile(null);
            } else {
                setError('');
                setFile(selectedFile);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Veuillez sélectionner un fichier.');
            return;
        }
        if (!sector) {
            setError('Veuillez sélectionner un secteur d\'activité.');
            return;
        }
        setError('');
        setFeedback('');
        setScore(null);
        setUploading(true);
        setUploadSuccess(false);
        setUploadError(false);
        setAnalysisComplete(false);
        setInsufficientCredits(false);
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('sector', sector);
    
        try {
            const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/cv/upload', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-User-Id': userId,
                },
                body: formData
            });
    
            const result = await response.json();
            console.log("Feedback reçu du backend:", result.feedback); // Vérification du contenu de feedback
            if (!response.ok) {
                if (result.error === "Crédits insuffisants pour effectuer cette action") {
                    setInsufficientCredits(true);
                    throw new Error(result.error);
                }
                throw new Error('Erreur lors du téléchargement du CV.');
            }
    
            setFeedback(result.feedback || "Aucune suggestion n'a été générée pour ce CV."); // Message par défaut si vide
            setScore(result.score !== undefined ? result.score : 0);
            setUploadSuccess(true);
            setAnalysisComplete(true);
            setReloadCredits(true);
    
            setCvContent(result.cvText || '');
        } catch (error) {
            setError(error.message);
            setUploadError(true);
        } finally {
            setUploading(false);
        }
    };
    

    const handleDownloadPDF = () => {
        const element = document.getElementById('cv-container');
        const options = {
            margin:       0.5,
            filename:     'cv-modifié.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        html2pdf().from(element).set(options).save();
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md transition-transform transform hover:scale-105">
                <div className="flex flex-col items-center mb-6">
                    <UserIcon className="w-16 h-16 text-blue-500" />
                    <div className="mt-2">
                        <UserCredits reload={reloadCredits} />
                    </div>
                </div>
                <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Téléchargez votre CV</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input 
                        type="file" 
                        onChange={handleFileChange} 
                        accept=".pdf,.docx" 
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                    />
<select
    value={sector}
    onChange={(e) => setSector(e.target.value)}
    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none mt-2"
>
    <option value="">Sélectionnez un secteur</option>
    <option value="informatique">Informatique</option>
    <option value="marketing">Marketing</option>
    <option value="finance">Finance</option>
    <option value="ressources_humaines">Ressources Humaines</option>
    <option value="santé">Santé</option>
    <option value="education">Éducation</option>
    <option value="logistique">Logistique</option>
    <option value="commerce">Commerce</option>
    <option value="ingenierie">Ingénierie</option>
    <option value="administration">Administration</option>
    <option value="juridique">Juridique</option>
    <option value="communication">Communication</option>
    <option value="construction">Construction</option>
    <option value="immobilier">Immobilier</option>
    <option value="agriculture">Agriculture</option>
    <option value="transports">Transports</option>
    <option value="tourisme">Tourisme</option>
    <option value="arts">Arts</option>
    <option value="recherche">Recherche</option>
    <option value="industrie">Industrie</option>
    <option value="environnement">Environnement</option>
    <option value="energie">Énergie</option>
    <option value="services_publics">Services Publics</option>
    <option value="service_client">Service Client</option>
    <option value="sport">Sport</option>
    <option value="mode">Mode</option>
    <option value="alimentation">Alimentation</option>
    <option value="bancaire">Bancaire</option>
    <option value="assurances">Assurances</option>
    <option value="biotechnologies">Biotechnologies</option>
    <option value="pharmaceutique">Pharmaceutique</option>
    <option value="chimie">Chimie</option>
    <option value="médias">Médias</option>
    <option value="audiovisuel">Audiovisuel</option>
    <option value="édition">Édition</option>
    <option value="cosmétique">Cosmétique</option>
    <option value="automobile">Automobile</option>
    <option value="aéronautique">Aéronautique</option>
    <option value="spatial">Spatial</option>
    <option value="defense">Défense</option>
    <option value="telecommunications">Télécommunications</option>
    <option value="nouvelles_technologies">Nouvelles Technologies</option>
    <option value="cybersecurite">Cybersécurité</option>
    <option value="ecommerce">E-commerce</option>
    <option value="distribution">Distribution</option>
    <option value="evenementiel">Événementiel</option>
    <option value="formation">Formation</option>
    <option value="restauration">Restauration</option>
    <option value="hôtellerie">Hôtellerie</option>
    <option value="recyclage">Recyclage</option>
    <option value="gestion_de_projet">Gestion de Projet</option>
    <option value="consulting">Consulting</option>
    <option value="artisanat">Artisanat</option>
    <option value="mines">Mines</option>
    <option value="metallurgie">Métallurgie</option>
    <option value="energie_renouvelable">Énergies Renouvelables</option>
    <option value="robotique">Robotique</option>
    <option value="intelligence_artificielle">Intelligence Artificielle</option>
</select>

                    {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
                    <button 
                        type="submit" 
                        className={`w-full mt-4 p-2 rounded-lg shadow transition duration-300 ${uploading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
                        disabled={uploading}
                    >
                        {uploading ? (
                            <div className="flex items-center justify-center">
                                <CircularProgress size={24} className="mr-2" />
                                Analyse en cours...
                            </div>
                        ) : (
                            'Télécharger'
                        )}
                    </button>
                </form>
                {analysisComplete && (
    <div id="cv-container" className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Modifiez votre CV</h2>
        <ReactQuill value={cvContent} onChange={setCvContent} theme="snow" className="bg-white p-4 rounded-lg shadow-md" />
    </div>
)}


                {analysisComplete && (
                    <button className="mt-4 p-2 bg-green-500 rounded-lg text-white" onClick={handleDownloadPDF}>
                        Télécharger le CV en PDF
                    </button>
                )}



                {/* Afficher un message si les crédits sont insuffisants */}
                {insufficientCredits && (
                    <div className="mt-6 bg-yellow-100 p-4 rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold text-yellow-700 mb-4">Crédits insuffisants</h2>
                        <p className="text-gray-600 text-center">
                            Vous n'avez pas assez de crédits pour analyser votre CV. Veuillez{' '}
                            <Link to="/subscription" className="text-blue-600 underline">
                                recharger votre compte
                            </Link>{' '}
                            pour continuer.
                        </p>
                    </div>
                )}

                {uploadSuccess && !analysisComplete && (
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
                        <div className="flex items-center text-blue-500 mb-4">
                            <CircularProgress size={24} className="mr-2" />
                            <h2 className="text-2xl font-bold">Analyse de votre CV en cours...</h2>
                        </div>
                        <p className="text-gray-600 text-center">Veuillez patienter pendant que notre IA analyse votre CV et génère des suggestions.</p>
                    </div>
                )}
                {analysisComplete && (
                    <div className={`mt-6 bg-white p-6 rounded-lg shadow-md ${uploadSuccess ? 'border-l-4 border-green-500' : 'border-l-4 border-red-500'}`}>
                        <div className="flex items-center mb-4">
                            {uploadSuccess ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                            ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                            )}
                            <h2 className="text-2xl font-bold">{uploadSuccess ? 'Suggestions d\'amélioration' : 'Erreur de traitement'}</h2>
                        </div>
                        <div className="bg-gray-100 p-4 rounded text-sm overflow-auto max-h-60">
                            <pre className="whitespace-pre-wrap">{feedback}</pre>
                        </div>
                        {score !== null && (
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold text-gray-800">Note de l'IA : {score}/100</h3>
                                <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
                                    <div className="bg-green-500 h-4 rounded-full" style={{ width: `${score}%` }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {uploadError && (
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-md border-l-4 border-red-500">
                        <div className="flex items-center mb-4 text-red-500">
                            <XCircleIcon className="w-6 h-6 mr-2" />
                            <h2 className="text-2xl font-bold">Erreur de téléchargement</h2>
                        </div>
                        <pre className="bg-gray-100 p-4 rounded text-sm whitespace-pre-wrap">{feedback}</pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CvUploader;
