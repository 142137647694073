import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, SelectInput, PasswordInput } from 'react-admin';

const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="username" />
            <TextInput source="email" />
            <TextInput source="credits" />
            <DateInput source="nextPaymentDate" />
            <SelectInput source="status" choices={[
                { id: 'active', name: 'Active' },
                { id: 'expired', name: 'Expired' },
                { id: 'cancelled', name: 'Cancelled' }
            ]} />
            <PasswordInput source="password" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
