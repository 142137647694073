import React from 'react';
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import CvUploader from './components/CvUploader';
import Register from './pages/Register';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Subscription from './pages/Subscription';
import SubscriptionInfo from './pages/SubscriptionInfo';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminDashboard from './pages/AdminDashboard';
import PrivateRoute from './components/PrivateRoute'; 
import PasswordResetRequest from './components/Password/PasswordResetRequesxt';
import PasswordReset from './components/Password/PasswordReset';


function App() {
    return (
        <Router>
            <div className="App">
            <Analytics />
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/upload" element={<PrivateRoute element={<CvUploader />} />} />
                    <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/subscription" element={<PrivateRoute element={<Subscription />} />} />
                    <Route path="/subscriptionInfo" element={<PrivateRoute element={<SubscriptionInfo />} />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />
                    <Route path="/admin/*" element={<PrivateRoute element={<AdminDashboard />} />} />
                    <Route path="/reset-password-request" element={<PasswordResetRequest />} />
                    <Route path="/reset-password/:token" element={<PasswordReset />} />
                </Routes>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
