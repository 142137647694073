import React, { useState, useEffect } from 'react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

const CarouselReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/reviews')
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => console.error('Erreur lors de la récupération des avis :', error));

    const interval = setInterval(() => {
      goToNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [reviews.length]);

  const goToNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        setIsTransitioning(false);
      }, 500);
    }
  };

  const goToPrevious = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
        setIsTransitioning(false);
      }, 500);
    }
  };

  return (
    <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
        <ChatBubbleBottomCenterTextIcon className="w-8 h-8 text-blue-600 mr-4" />
        Ce que nos utilisateurs disent
      </h2>
      {reviews.length > 0 ? (
        <div className="relative">
          {/* Conteneur du contenu de l'avis */}
          <div className="text-center mx-auto" style={{ padding: '0 40px' }}>
            <blockquote
              className={`italic text-gray-600 transition-opacity duration-500 ease-in-out ${
                isTransitioning ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {`"${reviews[currentIndex].comment}"`}
            </blockquote>
            <footer
              className={`mt-4 text-gray-800 transition-opacity duration-500 ease-in-out ${
                isTransitioning ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {`- ${reviews[currentIndex].user.username}`}
            </footer>
          </div>

          {/* Boutons précédent et suivant */}
          <button
            onClick={goToPrevious}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors"
            style={{ transform: 'translate(-50%, -50%)' }} // Sortir le bouton de la carte
          >
            ←
          </button>
          <button
            onClick={goToNext}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300 transition-colors"
            style={{ transform: 'translate(50%, -50%)' }} // Sortir le bouton de la carte
          >
            →
          </button>

          {/* Indicateurs visuels */}
          <div className="flex justify-center mt-4">
            {reviews.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full mx-1 ${
                  currentIndex === index ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      ) : (
        <p className="italic text-gray-600">Aucun avis disponible pour le moment.</p>
      )}
    </div>
  );
};

export default CarouselReviews;
