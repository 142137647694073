import React from 'react';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
                <svg className="w-16 h-16 text-green-500 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17h-2v-6h2v6zm2-6h-2V7h2v6z" />
                </svg>
                <h1 className="text-4xl font-extrabold text-gray-800 mb-4">Paiement Réussi !</h1>
                <p className="text-lg text-gray-600 mb-4">Votre paiement a été effectué avec succès. Merci pour votre achat !</p>
                <p className="text-lg text-gray-600 mb-6">Votre ID de session est : 
                    <span className="font-mono break-all inline-block bg-gray-100 p-1 rounded border border-gray-300">{sessionId}</span>
                </p>
                <a href="/" className="text-blue-500 hover:text-blue-700 font-semibold">Retour à l'accueil</a>
            </div>
        </div>
    );
};

export default Success;
