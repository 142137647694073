import React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput, useDataProvider } from 'react-admin';

const UserCreate = (props) => {
    const dataProvider = useDataProvider();

    const handleSave = async (values, redirectTo, basePath) => {
        try {
            await dataProvider.create('users', { data: values });
            // Si nécessaire, redirigez après la création réussie
            if (redirectTo) {
                props.history.push(redirectTo);
            }
        } catch (error) {
            // Gérer les erreurs ici
            console.error('Erreur lors de la création de l\'utilisateur:', error);
        }
    };

    return (
        <Create {...props} resource="users">
            <SimpleForm save={handleSave}>
                <TextInput source="username" />
                <TextInput source="email" />
                <PasswordInput source="password" />
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;
