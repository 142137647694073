// subscriptionSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Action asynchrone pour récupérer les informations d'abonnement
export const fetchSubscriptionInfo = createAsyncThunk(
    'subscription/fetchSubscriptionInfo',
    async (_, { getState, rejectWithValue }) => {
        const { token, user } = getState().auth;
        const userId = user.id;

        console.log(`Fetching subscription info for user: ${userId}`); // Log l'ID

        try {
            const response = await fetch(`https://cvanalyzerback.osc-fr1.scalingo.io/api/subscriptions/UserId/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch subscription information');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        resetSubscriptionState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptionInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubscriptionInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchSubscriptionInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetSubscriptionState } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
