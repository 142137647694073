import React, { useState } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'; // Importer les icônes de succès et d'erreur

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null); // null | true | false
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const { username, email, password } = formData;

    const regexEmail = /^[^@\s]{2,30}@[^@\s]{2,30}\.[^@\s]{2,5}$/;
    const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const validateEmail = (email) => {
        if (!regexEmail.test(email)) {
            setEmailError("L'adresse email n'est pas valide.");
        } else {
            setEmailError('');
        }
    };

    const validatePassword = (password) => {
        if (!regexPassword.test(password)) {
            setPasswordError('Le mot de passe doit comporter au moins 8 caractères, avec au moins une majuscule, une minuscule, un chiffre et un symbole.');
        } else {
            setPasswordError('');
        }
    };

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'email') {
            validateEmail(e.target.value);
        }

        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        if (emailError || passwordError) {
            return; // Ne pas soumettre si des erreurs existent
        }

        setIsSubmitting(true);
        setIsSuccess(null);
        setErrorMessage('');
        try {
            const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/users/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, email, password })
            });
            const data = await response.json();
            if (response.ok) {
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
                setErrorMessage(data.message || 'Une erreur est survenue, veuillez réessayer.');
            }
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement de l\'utilisateur:', error);
            setIsSuccess(false);
            setErrorMessage('Erreur de réseau, veuillez réessayer.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            {isSuccess === true ? (
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
                    <CheckCircleIcon className="w-16 h-16 text-green-500 mx-auto mb-4" />
                    <h2 className="text-2xl font-bold mb-4 text-gray-800">Inscription réussie !</h2>
                    <p className="text-gray-600 mb-4">Vous pouvez maintenant vous connecter avec vos identifiants.</p>
                    <a href="/login" className="text-blue-600 hover:underline">Se connecter</a>
                </div>
            ) : (
                <form onSubmit={onSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                    <h2 className="text-3xl font-bold mb-6 text-gray-800 flex items-center">
                        <span>Créer un Compte</span>
                    </h2>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                            <span className="mr-2">Nom d'utilisateur :</span>
                        </label>
                        <input
                            type="text"
                            name="username"
                            value={username}
                            onChange={onChange}
                            required
                            className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                            <span className="mr-2">Email :</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                            className={`mt-1 p-3 w-full border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        />
                        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                            <span className="mr-2">Mot de passe :</span>
                        </label>
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={onChange}
                            required
                            className={`mt-1 p-3 w-full border ${passwordError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        />
                        {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
                    </div>
                    <button
                        type="submit"
                        disabled={isSubmitting || emailError || passwordError}
                        className={`w-full ${isSubmitting ? 'bg-gray-400' : 'bg-blue-600'} text-white p-3 rounded-lg font-semibold hover:${isSubmitting ? 'bg-gray-400' : 'bg-blue-700'} transition duration-300 flex items-center justify-center`}
                    >
                        {isSubmitting ? (
                            <>
                                <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="none" stroke="currentColor" strokeWidth="4" d="M4 12a8 8 0 0116 0"></path>
                                </svg>
                                Envoi en cours...
                            </>
                        ) : (
                            'S\'inscrire'
                        )}
                    </button>
                    {errorMessage && (
                        <div className="mt-4 text-red-600 flex items-center">
                            <ExclamationCircleIcon className="w-6 h-6 mr-2" />
                            <span>{errorMessage}</span>
                        </div>
                    )}
                </form>
            )}
        </div>
    );
};

export default Register;
