import React, { useState } from 'react';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  // Regex pour la validation de l'email
  const regexEmail = /^[^@\s]{2,30}@[^@\s]{2,30}\.[^@\s]{2,5}$/;

  const validateEmail = (email) => {
    if (!regexEmail.test(email)) {
      setEmailError("L'adresse email n'est pas valide.");
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valider l'email avant d'envoyer la requête
    if (!regexEmail.test(email)) {
      setEmailError("L'adresse email n'est pas valide.");
      return; // Ne pas soumettre si l'email est invalide
    }

    try {
      const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/password/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Email de réinitialisation envoyé.');
        setError('');
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('Une erreur est survenue.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value); // Valider l'email à chaque changement
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
      <h1 className="text-5xl font-extrabold text-gray-800 mb-8">Demande de réinitialisation de mot de passe</h1>
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange} // Utilisation de handleEmailChange pour validation en direct
            required
            className={`w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
          />
          {emailError && <p className="text-red-500 mt-1 text-sm">{emailError}</p>}
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-lg">
          Envoyer
        </button>
        {message && <p className="text-green-500 mt-4">{message}</p>}
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </form>
    </div>
  );
};

export default PasswordResetRequest;
