import { createSlice } from '@reduxjs/toolkit';
import { resetSubscriptionState } from './subscriptionSlice';

const initialState = {
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateCredits: (state, action) => {
      if (state.user) {
        state.user.credits = action.payload;
      }
    },
  },
});

export const { loginSuccess, logout, updateUser, updateCredits } = authSlice.actions;

export const performLogout = () => (dispatch) => {
  dispatch(logout());
  dispatch(resetSubscriptionState());
};

export default authSlice.reducer;
