import React, { useState } from 'react';

const AdminPasswordPrompt = ({ onAccessGranted }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === adminPassword) {
            onAccessGranted(true);
        } else {
            setError('Mot de passe incorrect');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-3xl font-bold mb-4 text-gray-800">Mot de passe Administrateur</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Entrez le mot de passe administrateur"
                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
                />
                <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                >
                    Valider
                </button>
            </form>
        </div>
    );
};

export default AdminPasswordPrompt;
