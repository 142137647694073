import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { RocketLaunchIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'; 

const stripePromise = loadStripe('pk_live_51Pdd5hRrJ2IEm8V5HhU0bRzo5NaDfTuk89unIlU3ZOeF50pfpaIBPl35dg84juaaULjlsvXQKFbziEMEYlJFCM2b00msjtlVAO');

const Subscription = () => {
    const userId = useSelector((state) => state.auth.user.id);
    const token = useSelector((state) => state.auth.token);
    
    const [transactionType, setTransactionType] = useState('subscription'); // 'subscription' or 'credits'
    const [creditAmount, setCreditAmount] = useState(5); // Montant par défaut

    const handleClick = async (type) => {
        setTransactionType(type);

        try {
            const endpoint = type === 'subscription'
                ? 'https://cvanalyzerback.osc-fr1.scalingo.io/api/stripe/create-checkout-session'
                : 'https://cvanalyzerback.osc-fr1.scalingo.io/api/stripe/create-credit-purchase-session';

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId: userId,
                    amount: creditAmount // Inclure le montant des crédits
                }),
            });

            if (!response.ok) {
                throw new Error('Échec de la création de la session de paiement');
            }

            const session = await response.json();
            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                console.error('Erreur Stripe Checkout:', error);
            }
        } catch (error) {
            console.error('Échec de la création de la session de paiement:', error);
            alert('Une erreur est survenue. Veuillez réessayer.');
        }
    };

    return (
        <Elements stripe={stripePromise}>
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
                    <div className="flex items-center justify-center mb-6">
                        <RocketLaunchIcon className="w-12 h-12 text-blue-500 mr-3" />
                        <h1 className="text-3xl font-bold text-gray-800">Accédez à nos services Premium</h1>
                    </div>
                    <p className="text-gray-600 mb-6">
                        🚀 <strong>Déclenchez la puissance de l'IA</strong> pour perfectionner votre CV et maximiser vos chances d'obtenir le poste de vos rêves. Avec des crédits, accédez à des fonctionnalités exclusives qui vous aideront à briller auprès des recruteurs !
                    </p>
                    <div className="flex items-center justify-center mb-6">
                        <ChatBubbleBottomCenterTextIcon className="w-10 h-10 text-blue-400 mr-2" />
                        <p className="text-gray-600">
                            Profitez d'une expérience sans publicité et d'analyses approfondies de votre CV pour vous démarquer dans le marché du travail.
                        </p>
                    </div>
                    <div className="flex flex-col space-y-4 mb-6">
                        {/* <button
                            onClick={() => handleClick('subscription')}
                            className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
                            aria-label="S'abonner pour 9,99 €/mois"
                        >
                            S'abonner pour 9,99 €/mois
                        </button>
                        Ou */}
                        <div className="flex flex-col items-center space-y-4">
                            <input
                                type="number"
                                min="5"
                                value={creditAmount}
                                onChange={(e) => setCreditAmount(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                placeholder="Montant en euros"
                            />
                            <button
                                onClick={() => handleClick('credits')}
                                className="bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-green-600 transition duration-300"
                                aria-label="Acheter des crédits"
                            >
                                Acheter {creditAmount}€ de crédits
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Elements>
    );
};

export default Subscription;
