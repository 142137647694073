// LogoutButton.jsx
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { performLogout } from '../slices/authSlice';

const LogoutButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(performLogout());
        navigate('/login'); // Rediriger vers la page de connexion
    };

    return (
        <li
        onClick={handleLogout}
                 >
                    <Link   className="text-white hover:bg-gray-700 p-2 rounded transition duration-300">
 
            Déconnexion</Link>
        </li>
    );
};

export default LogoutButton;
