import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        // Redirige l'utilisateur vers la page de paiement ou la page précédente
        navigate('/subscription'); // Remplacez '/subscription' par la route appropriée
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
                <svg className="w-16 h-16 text-red-500 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17h-2v-6h2v6zm2-6h-2V7h2v6z" />
                </svg>
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Paiement Annulé</h1>
                <p className="text-lg text-gray-600 mb-6">Vous avez annulé le paiement. Si vous souhaitez réessayer, veuillez cliquer sur le bouton ci-dessous.</p>
                <button
                    onClick={handleRetry}
                    className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition duration-300"
                >
                    Réessayer
                </button>
                <p className="text-sm text-gray-500 mt-4">Ou retournez à <a href="/" className="text-blue-500 hover:text-blue-700">la page d'accueil</a>.</p>
            </div>
        </div>
    );
};

export default Cancel;
