import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import illustration from '../assets/illustration.webp';
import { UserIcon, DocumentTextIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'; 
import CarouselReviews from '../components/CarouselReview';
import ModalReview from '../components/ModalReview';

const Home = () => {
    const user = useSelector((state) => state.auth.user);
    const [showModal, setShowModal] = useState(false); // Gestion de la modal

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6 sm:p-12 lg:p-24">
            {/* Personnalisation du title et description meta */}
            <Helmet>
            <title>CV Analyzer - Optimisez votre CV et décrochez le job de vos rêves</title>
            <meta name="description" content="Analyse de CV en ligne avec des recommandations personnalisées pour attirer l’attention des recruteurs. Boostez vos chances d'embauche avec CV Analyzer." />

            </Helmet>

            <div className="text-center mb-12">
                {user ? (
                    <>
                        <h1 className="text-5xl font-extrabold mb-8 text-gray-800">Bienvenue, {user.username} sur CV Analyzer</h1>
                        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
                            Prêt à améliorer votre CV aujourd'hui ?
                        </h2>
                        <div className="relative mb-8 w-full max-w-2xl mx-auto aspect-w-16 aspect-h-9">
                            <img 
                                src={illustration} 
                                alt="Illustration d'un outil d'analyse de CV" 
                                className="object-cover rounded-xl shadow-xl transition-transform transform hover:scale-105"
                            />
                        </div>
                        <h2 className="text-3xl font-bold mb-8 text-gray-800">Optimisez votre CV pour attirer les recruteurs</h2>
                        <p className="text-lg mb-8 text-center max-w-3xl mx-auto text-gray-600">
                            Grâce à notre outil CV Analyzer, bénéficiez d'analyses détaillées et de recommandations sur mesure pour rendre votre CV plus attractif et décrocher l'emploi de vos rêves. Ne laissez aucune opportunité vous échapper !
                        </p>
                        <div className="mt-8 flex flex-col md:flex-row gap-6 justify-center">
                            <Link 
                                to="/upload" 
                                className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-colors flex items-center"
                            >
                                <DocumentTextIcon className="w-6 h-6 mr-2" />
                                Analyser un CV
                            </Link>
                            <Link 
                                to="/profile" 
                                className="bg-gray-300 text-gray-800 py-3 px-6 rounded-lg shadow-md hover:bg-gray-400 transition-colors flex items-center"
                            >
                                <UserIcon className="w-6 h-6 mr-2" />
                                Voir mon Profil
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="text-5xl font-extrabold mb-8 text-gray-800">Bienvenue sur CV Analyzer</h1>
                        <div className="relative mb-8 w-full max-w-2xl mx-auto aspect-w-16 aspect-h-9">
                            <img 
                                src={illustration}  
                                alt="Illustration de bienvenue pour CV Analyzer" 
                                className="object-cover rounded-xl shadow-xl transition-transform transform hover:scale-105"
                            />
                        </div>
                        <p className="text-lg mb-8 text-center max-w-3xl mx-auto text-gray-600">
                            Découvrez notre plateforme d'analyse de CV et inscrivez-vous pour bénéficier d'une optimisation de CV personnalisée. Rejoignez dès maintenant notre communauté pour recevoir des conseils et des analyses spécifiques à vos besoins professionnels.
                        </p>
                        <div className="mt-8 flex flex-col md:flex-row gap-6 justify-center">
                            <Link 
                                to="/register" 
                                className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-colors flex items-center"
                            >
                                <UserIcon className="w-6 h-6 mr-2" />
                                S'inscrire
                            </Link>
                            <Link 
                                to="/login" 
                                className="bg-gray-300 text-gray-800 py-3 px-6 rounded-lg shadow-md hover:bg-gray-400 transition-colors flex items-center"
                            >
                                <UserIcon className="w-6 h-6 mr-2" />
                                Se Connecter
                            </Link>
                        </div>
                    </>
                )}
            </div>

            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                    <div className="flex items-center mb-4">
                        <DocumentTextIcon className="w-8 h-8 text-blue-600 mr-4" />
                        <h2 className="text-xl font-semibold text-gray-800">Analyse de CV</h2>
                    </div>
                    <p className="text-gray-600">
                        Améliorez votre CV avec des recommandations personnalisées basées sur votre secteur et vos expériences professionnelles. Rendez votre CV plus attractif pour les recruteurs et multipliez vos chances de décrocher des entretiens.
                    </p>
                    {user ? (
                        <Link 
                            to="/analyze" 
                            className="mt-4 inline-block text-blue-600 hover:underline"
                        >
                            Commencer
                        </Link>
                    ) : (
                        <p className="mt-4 text-gray-500">Connectez-vous pour commencer l'analyse de votre CV.</p>
                    )}
                </div>

                <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                    <div className="flex items-center mb-4">
                        <UserIcon className="w-8 h-8 text-gray-600 mr-4" />
                        <h2 className="text-xl font-semibold text-gray-800">Profil</h2>
                    </div>
                    <p className="text-gray-600">
                        Accédez à votre profil pour mettre à jour vos informations personnelles et professionnelles. Assurez-vous que vos données sont toujours à jour pour recevoir des recommandations précises.
                    </p>
                    {user ? (
                        <Link 
                            to="/profile" 
                            className="mt-4 inline-block text-blue-600 hover:underline"
                        >
                            Voir Profil
                        </Link>
                    ) : (
                        <p className="mt-4 text-gray-500">Connectez-vous pour voir et mettre à jour votre profil.</p>
                    )}
                </div>
            </div>

            {/* Carrousel des avis utilisateurs */}
            <div className="mt-12">
                <CarouselReviews />
            </div>

            {/* Bouton pour afficher la modal d'ajout d'avis */}
            {user && (
                <button 
                    onClick={() => setShowModal(true)} 
                    className="mt-8 bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-colors"
                >
                    Laisser un avis
                </button>
            )}

            {/* Modal pour poster un avis */}
            {showModal && (
                <ModalReview onClose={() => setShowModal(false)} />
            )}
        </div>
    );
};

export default Home;
