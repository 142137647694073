import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Utilise localStorage
import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import authMiddleware from './middleware/authMiddleware';

// Configuration de redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Liste des reducers à persister
};

// Combinez vos reducers
const rootReducer = combineReducers({
  auth: authReducer,
  subscription: subscriptionReducer,
});

// Créez un reducer persistant
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configurez le store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(authMiddleware),
});

// Créez un persistor
export const persistor = persistStore(store);

export default store;
