import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCredits, logout } from '../slices/authSlice'; 
import { useNavigate } from 'react-router-dom';

const UserCredits = ({ reload }) => {
    const dispatch = useDispatch();
    const credits = useSelector((state) => state.auth.user?.credits);
    const userId = useSelector((state) => state.auth.user?.id);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const navigate = useNavigate();

    // Fonction pour récupérer les crédits
    const fetchUpdatedCredits = async () => {
        try {
            setLoading(true); // Démarrer le chargement
            const response = await fetch(`https://cvanalyzerback.osc-fr1.scalingo.io/api/users/${userId}/credits`);

            if (!response.ok) {
                if (response.status === 401) {
                    // Si le token est expiré ou invalide, forcer la déconnexion
                    dispatch(logout());
                    navigate('/login'); // Rediriger vers la page de connexion
                } else {
                    throw new Error('Erreur lors de la récupération des crédits');
                }
            }

            const data = await response.json();
            
            // Mettre à jour les crédits dans le store
            dispatch(updateCredits(data.credits));
            setLoading(false); // Terminer le chargement
        } catch (error) {
            console.error('Erreur lors de la récupération des crédits:', error);
            setError('Impossible de récupérer les crédits');
            setLoading(false); // Terminer le chargement même en cas d'erreur
        }
    };

    // Charger les crédits lors de la première montée du composant
    useEffect(() => {
        if (userId) {
            fetchUpdatedCredits(); 
        }
    }, [dispatch, userId]); // Premier effet sans dépendance de reload

    // Recharger les crédits si reload change
    useEffect(() => {
        if (userId && reload) {
            fetchUpdatedCredits(); 
        }
    }, [reload, dispatch, userId]); // Deuxième effet qui écoute le changement de reload

    if (loading) {
        return <p>Chargement des crédits...</p>;
    }

    if (error) {
        return <p className="text-red-500">{error}</p>;
    }

    return (
        <p><strong>Crédits actuels :</strong> {credits !== null ? credits : '0'}</p>
    );
};

export default UserCredits;
