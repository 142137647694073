import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const ModalReview = ({ onClose }) => {
  const token = useSelector((state) => state.auth.token);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(5);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Appel à l'API pour poster un avis
    fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/reviews', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment, rating }),
    })
      .then((response) => response.json())
      .then(() => {
        alert('Avis posté avec succès !');
        onClose(); // Fermer la modal après soumission
      })
      .catch((error) => {
        console.error('Erreur lors de la création de l\'avis :', error);
        alert('Une erreur est survenue. Veuillez réessayer.');
      });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">Poster un avis</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Commentaire :</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Note :</label>
            <select
              value={rating}
              onChange={(e) => setRating(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="5">5 - Excellent</option>
              <option value="4">4 - Très bien</option>
              <option value="3">3 - Bien</option>
              <option value="2">2 - Moyen</option>
              <option value="1">1 - Mauvais</option>
            </select>
          </div>
          <div className="flex justify-end gap-4">
            <button type="button" className="bg-gray-300 text-gray-700 px-4 py-2 rounded" onClick={onClose}>
              Annuler
            </button>
            <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
              Poster
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalReview;
