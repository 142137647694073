import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, logout } from '../slices/authSlice';
import { UserIcon, EnvelopeIcon, LockClosedIcon, ArrowRightIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'; 
import UserCredits from '../components/UserCredits'; 
import { useNavigate } from 'react-router-dom';
import store from '../store.js';

const Profile = () => {
    const token = useSelector((state) => state.auth.token);
    const [profile, setProfile] = useState({ username: '', email: '' });
    const [formData, setFormData] = useState({ username: '', email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const regexEmail = /^[^@\s]{2,30}@[^@\s]{2,30}\.[^@\s]{2,5}$/;
    const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const validateEmail = (email) => {
        if (!regexEmail.test(email)) {
            setEmailError("L'adresse email n'est pas valide.");
        } else {
            setEmailError('');
        }
    };

    const validatePassword = (password) => {
        if (!regexPassword.test(password)) {
            setPasswordError('Le mot de passe doit comporter au moins 8 caractères, avec au moins une majuscule, une minuscule, un chiffre et un symbole.');
        } else {
            setPasswordError('');
        }
    };

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/users/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    if (response.status === 401) {
                        // Si le token est expiré ou invalide, forcer la déconnexion
                        dispatch(logout());
                        navigate('/login'); // Rediriger vers la page de connexion
                    } else {
                        throw new Error('Erreur lors de la récupération des crédits');
                    }
                }
                const data = await response.json();
                setProfile(data);
                setFormData({ username: data.username, email: data.email, password: '' });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [token]);

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'email') {
            validateEmail(e.target.value);
        }

        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (emailError || passwordError) {
            setLoading(false);
            return; // Ne pas soumettre si des erreurs existent
        }

        try {
            const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/users/profile', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) throw new Error('Échec de la mise à jour du profil');
            const data = await response.json();
            alert('Profil mis à jour avec succès');
            setProfile({ username: formData.username, email: formData.email });
            dispatch(updateUser({ username: formData.username, email: formData.email }));
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
            <h1 className="text-5xl font-extrabold text-gray-800 mb-8">Votre Profil</h1>
            
            {/* Section UserCredits avec une icône */}
            <div className="bg-white p-4 mb-6 rounded-lg shadow-lg w-full max-w-lg border border-gray-200 text-center">
                <div className="flex items-center justify-center space-x-2">
                    <CurrencyDollarIcon className="w-6 h-6 text-gray-500" /> {/* Icône ajoutée */}
                    <UserCredits /> {/* Affichage des crédits actuels */}
                </div>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg border border-gray-200">
                {loading && <div className="flex items-center space-x-2 mb-4"><svg className="w-6 h-6 text-blue-500 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 2v2a6 6 0 0 1 0 12v2a8 8 0 0 0 0-16z" /></svg><span className="text-blue-500">Mise à jour en cours...</span></div>}
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                
                <form onSubmit={onSubmit} className="space-y-6">
                    <div className="flex items-center border border-gray-300 rounded-lg shadow-sm">
                        <UserIcon className="w-6 h-6 text-gray-500 ml-3" />
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={onChange}
                            required
                            placeholder="Nom d'utilisateur"
                            className="w-full px-4 py-3 border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                        />
                    </div>
                    <div className="flex items-center border border-gray-300 rounded-lg shadow-sm">
                        <EnvelopeIcon className="w-6 h-6 text-gray-500 ml-3" />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                            required
                            placeholder="Email"
                            className={`w-full px-4 py-3 border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out ${emailError ? 'border-red-500' : ''}`}
                        />
                    </div>
                    {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                    
                    <div className="flex items-center border border-gray-300 rounded-lg shadow-sm">
                        <LockClosedIcon className="w-6 h-6 text-gray-500 ml-3" />
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={onChange}
                            placeholder="Mot de passe (laisser vide si inchangé)"
                            className={`w-full px-4 py-3 border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out ${passwordError ? 'border-red-500' : ''}`}
                        />
                    </div>
                    {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
                    
                    <div className="flex justify-center items-center">
                        {loading ? (
                            <div className="flex items-center space-x-2">
                                <svg className="w-5 h-5 text-blue-500 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 2v2a6 6 0 0 1 0 12v2a8 8 0 0 0 0-16z" /></svg>
                                <span className="text-blue-500">Mise à jour en cours...</span>
                            </div>
                        ) : (
                            <button type="submit" className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 flex items-center space-x-2">
                                <ArrowRightIcon className="w-5 h-5" />
                                <span>Mettre à jour le profil</span>
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Profile;
